import dayjs from "dayjs";

import type { Badge, Promotion } from "@/types";

const exclusionSet = new Set(["invite", "inviteQualification", "dailyWheel", "tournament", "season"]);

const selectPrizeEntries = (promotion: Promotion) => {
	if (
		promotion.type === "partnerReferralQualification" ||
		promotion.type === "partnerReferralQualificationByVerification"
	) {
		return promotion.data?.bonusEntries;
	}
	return promotion.data?.prizeEntries;
};
const selectPrizeCoins = (promotion: Promotion) => {
	if (
		promotion.type === "partnerReferralQualification" ||
		promotion.type === "partnerReferralQualificationByVerification"
	) {
		return promotion.data?.bonusCoins;
	}
	return promotion.data?.prizeCoins;
};
const selectTooltipText = ({ t, promotion }: { t: ReturnType<typeof useT>["t"]; promotion: Promotion }) => {
	const daysOfWeek = promotion.data?.promoOfferPreset?.dayOfWeek || [];
	const hasOneDay = daysOfWeek.length === 1;
	if (promotion.data?.needDeposits) {
		return t("Available after the th purchase", { msg: promotion.data?.needDeposits });
	}
	if (promotion.data?.promoOfferPreset?.isLimitPerDayReason) {
		if (!hasOneDay) {
			return t("Can be claimed again next weekends.");
		}
		const index = parseInt(daysOfWeek[0]);
		const dayOfWeek = dayjs().day(index).format("dddd");
		return t("Available after the", { day: t(dayOfWeek) });
	}
	if (promotion.data?.promoOfferPreset?.isMinBalanceReason) {
		return t("Can be claimed again when your total entries + winnings + pending redeems are less than.", {
			msg: promotion.data?.promoOfferPreset.minBalance
		});
	}
	if (hasOneDay) {
		const index = parseInt(daysOfWeek[0]);
		const dayOfWeek = dayjs().day(index).format("dddd");
		return t("Available on", { day: t(dayOfWeek) });
	}
	return t("Available on weekends");
};
const selectBadgeLabel = (promotion: Promotion): Badge =>
	parseJSON(promotion.data?.promoOfferPreset?.badgeLabel || "{}");
const selectDate = (promotion: Promotion) =>
	dayjs().add(
		promotion.data?.promoOfferPreset?.availableTill ? Number(promotion.data?.promoOfferPreset?.availableTill) : 0,
		"second"
	);
const selectPackage = ({
	entries,
	coins,
	promotion,
	isSweepStakes,
	t
}: {
	entries: number;
	coins: number;
	promotion: Promotion;
	isSweepStakes: boolean;
	t: ReturnType<typeof useT>["t"];
}) => {
	const packages = {
		dailyWheel: {
			title: t("once a day offer"),
			entries: 500,
			coins: 250000,
			type: "dailyWheel",
			imagePopup: "/nuxt-img/promotions/wheel.jpg",
			subTitle: t("get up to")
		},
		inviteQualification: {
			title: t("invite friends program"),
			entries,
			coins,
			type: "inviteQualification",
			imagePopup: "/nuxt-img/promotions/get-qualified.jpg",
			subTitle: t("get qualified to get")
		},
		partnerReferralQualification: {
			title: t("Personal Promo"),
			entries,
			coins,
			type: "inviteQualification",
			imagePopup: "/nuxt-img/promotions/get-qualified.jpg",
			subTitle: ""
		},
		verification: {
			title: t("Secure your game"),
			entries: 1000,
			coins: 0,
			type: "verification",
			imagePopup: "/nuxt-img/promotions/verification.jpg",
			subTitle: t("Upgrade account and get")
		},
		invite: {
			title: t("invite friends program"),
			entries,
			coins,
			type: "invite",
			imagePopup: "/nuxt-img/promotions/invite-friend.jpg",
			subTitle: t("Share the Rewards!")
		},
		tournament: {
			title: t("funrize races"),
			entries: 0,
			coins: 0,
			type: "tournament",
			imagePopup: isSweepStakes ? "/nuxt-img/promotions/races-entries.jpg" : "/nuxt-img/promotions/races.jpg",
			subTitle: isSweepStakes ? t("win real prizes by fee play") : t("win coins by free play!")
		},
		rushHourOffers: {
			title: "",
			entries: 0,
			coins: 0,
			type: "rushHourOffers",
			imagePopup: "",
			subTitle: ""
		},
		partnerReferralQualificationByVerification: {
			title: t("Secure your freeplay"),
			entries,
			coins,
			type: "partnerReferralQualificationByVerification",
			imagePopup: "/nuxt-img/promotions/verification.jpg",
			subTitle: t("Verify your account to get")
		},
		offerDeposit: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			subTitle: ""
		},
		season: {
			title: t("season.title"),
			entries: 100000,
			coins: 0,
			type: "season",
			imagePopup: "/nuxt-img/promotions/seasons.webp",
			subTitle: t("season.promo.earn")
		},
		quest: {
			title: t("Quest"),
			entries: 100000,
			coins: 0,
			type: "quest",
			imagePopup: "/nuxt-img/advent/promo.jpg",
			subTitle: t("Collect guaranteed rewards")
		},
		flipTheCoin: {
			title: t("Flip Lucky Coin"),
			entries: 0,
			coins: 0,
			type: "flipTheCoin",
			imagePopup: "/nuxt-img/promotions/flip-coin.jpg",
			subTitle: t("Get even more")
		},
		prizeDrops: {
			title: "",
			entries: 0,
			coins: 0,
			type: "prizeDrops",
			imagePopup: "",
			subTitle: ""
		},
		bingo: {
			title: "",
			entries: 0,
			coins: 0,
			type: "bingo",
			imagePopup: "",
			subTitle: ""
		},
		depositStreak: {
			title: "",
			entries: 0,
			coins: 0,
			type: "depositStreak",
			imagePopup: "",
			subTitle: ""
		}
	};
	if (promotion.data?.promoOfferPreset) {
		return promotion.data?.promoOfferPreset;
	}
	const packageType = promotion.type as keyof typeof packages;
	if (!(packageType in packages)) {
		return;
	}
	return packages[packageType];
};

export const createDecorator = (t: ReturnType<typeof useT>["t"]) => (promotion: Promotion, isSweepStakes: boolean) => {
	const badgeLabel = selectBadgeLabel(toRaw(promotion));
	const tooltipText = selectTooltipText({ t, promotion });
	const prizeEntries = selectPrizeEntries(toRaw(promotion));
	const prizeCoins = selectPrizeCoins(toRaw(promotion));
	const date = selectDate(promotion);
	const pkg = selectPackage({
		t,
		entries: prizeEntries || 0,
		coins: prizeCoins || 0,
		promotion,
		isSweepStakes
	});

	return {
		...promotion,
		package: pkg,
		hasExclusionType: exclusionSet.has(promotion.type || ""),
		date,
		tooltipText,
		badgeLabel
	};
};
